import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '@/views/home'
// import Layout from '@/views/layout'
const Layout = () => import("@/views/layout");
const BallTool = () => import("@/views/layout/BallTool");
const Home = () => import("@/views/home");
const Question = () => import("@/views/question");
const QuestionDetail = () => import("@/views/question/detail");
const QuestionMiniDetail = () => import("@/views/question/minidetail");

const QuestionType = () => import("@/views/questiontype");
const QuestionDetailType = () => import("@/views/questiontype/detail");

const Starplan = () => import("@/views/question/starplan");
const StarDetail = () => import("@/views/question/stardetail");

const Video = () => import("@/views/video");
const User = () => import("@/views/user");
const UserProfile = () => import("@/views/user/profile");
const ChangePassWord = () => import("@/views/user/ChangePassWord");
const ChangeUserInfo = () => import("@/views/user/ChangeUserInfo");
const BindWX = () => import("@/views/user/BindWX");
const BindWXPre = () => import("@/views/user/BindWXPre");
const BindA3 = () => import("@/views/user/BindA3");
const FeedBack = () => import("@/views/user/Feedback");
const MyCourse = () => import("@/views/user/mycourse");
const MyBBS = () => import("@/views/user/mybbs");
const MyScore = () => import("@/views/user/myscore");
const UserCollect = () => import("@/views/user/UserCollect");

const Login = () => import("@/views/login");
const Search = () => import("@/views/search");
const SearchResult = () => import("@/views/search/result");
const SearchList = () => import("@/views/search/list");
const Article = () => import("@/views/article");
const ArticleDetail = () => import("@/views/article/detail");

const HD = () => import("@/views/hd/list");
const HDDetail = () => import("@/views/hd/detail");

const ArticlePlus = () => import("@/views/articleplus");
const ArticleDetailPlus = () => import("@/views/articleplus/detail");

const Product = () => import("@/views/product");
const ProductDetail = () => import("@/views/product/detail");
const ProductList = () => import("@/views/product/list");
const School = () => import("@/views/school");
const Course = () => import("@/views/course");
const CourseClass = () => import("@/views/course/courseClass");
const CourseList = () => import("@/views/course/list");
const CourseDetail = () => import("@/views/course/detail");
const CloudDisk = () => import("@/views/clouddisk");
const CloudDiskDetail = () => import("@/views/clouddisk/detail");

const CourseOfflineDetail = () => import("@/views/course/courseofflineDetail");

const AddCourse = () => import("@/views/course/add");
const AddBBS = () => import("@/views/bbs/add");

const BBSList = () => import("@/views/bbs/list");
const BBSDetail = () => import("@/views/bbs/detail");
const S7Strategy = () => import("@/views/s7strategy/list");

const ScoreRank = () => import("@/views/score/rank");
const Ad = () => import("@/views/ad");
const AdList = () => import("@/views/ad/list");

const ProductArrival = () => import("@/views/productArrival");
const ArrivalDetail = () => import("@/views/productArrival/arrivalDetail");
const CircularDetail = () => import("@/views/productArrival/circularDetail");
const MyCourseOffline = () => import("@/views/user/mycourseoffline");
const MyCourseOfflineDetail = () =>
  import("@/views/user/mycourseofflinedetail");
const MyCourseofflineSignin = () =>
  import("@/views/user/mycourseofflineSignin");
const EshopDetail = () => import("@/views/eshop/detail");
const Eshop = () => import("@/views/eshop");
const ProductProject = () => import("@/views/productProject");
const SystemMessage = () => import("@/views/user/SystemMessage");
const SystemMessageDetail = () => import("@/views/user/SystemMessageDetail");
const MyComment = () => import("@/views/user/mycomment");
const MyCommentLike = () => import("@/views/user/mycommentlike");
const UserCourseTime = () => import("@/views/user/UserCourseTime");
const CourseComment = () => import("@/views/coursecomment");
const AnserDetail = () => import("@/views/question/answerDetail");
const ProductPrice = () => import("@/views/productPrice");
const Test = () => import("@/views/test");
const KManser = () => import("@/views/question/kmanswer");
const Annualmeeting = () => import("@/views/annualmeeting");
const worklogAdd = () => import("@/views/worklog/add");
const worklogList = () => import("@/views/worklog/list");
const worklogDetail = () => import("@/views/worklog/detail");

const PreChangePassWord = () => import("@/views/user/PreChangePassWord");
const CheckSmsCode = () => import("@/views/user/CheckSmsCode");
const FindPassWord = () => import("@/views/user/FindPassWord");
const mockUser = () => import("@/views/mockUser");

const SystemMessageBirthday = () =>
  import("@/views/user/SystemMessageBirthday");
const SystemMessageJoinDate = () =>
  import("@/views/user/SystemMessageJoinDate");
const UserHomepage = () => import("@/views/userHomepage");

const workSignin = () => import("@/views/workSignin");
const Share = () => import("@/views/Share");
const workSigninDepartmentData = () =>
  import("@/views/workSignin/DepartmentData");

const customizedWineNeedAdd = () => import("@/views/customizedWineNeed/add");
const customizedWineNeedList = () => import("@/views/customizedWineNeed/list");
const frame = () => import("@/views/frame/index");

const exam = () => import("@/views/exam");
const answerList = () => import("@/views/exam/answerList");
const answerDetail = () => import("@/views/exam/answerDetail");
const myCertificate = () => import("@/views/user/myCertificate");
const userCertificate = () => import("@/views/user/UserCertificate");
const myCertificateDetail = () => import("@/views/user/myCertificateDetail");
const luckDraw = () => import("@/views/LuckDraw");
const luckDrawRecord = () => import("@/views/LuckDraw/record");

const mySignin = () => import("@/views/workSignin/mySignin");
const SettingList = () => import("@/views/user/SettingList");
const Openminkm = () => import("@/views/OpenMini/km");
const MySubscribe = () => import("@/views/user/MySubscribe");
const Violation = () => import("@/views/Violation");

const Dealer = () => import("@/views/dealer");
const DealerStat = () => import("@/views/dealer/dealerStat");
const EditeDealer = () => import("@/views/dealer/editdealer");
const DealerCampaign = () => import("@/views/dealer/dealerCampaign");
const CampaignDetail = () => import("@/views/dealer/CampaignDetail");
const autotest = () => import("@/views/dealer/autotest");
const StatIndex = () => import("@/views/dealer/StatIndex");
const vip = () => import("@/views/vip");
const paradetail = () => import("@/views/question/paradetail");
const cardmeber=()=>import("@/views/question/cardmeber");
const longcard=()=>import("@/views/question/longcard");
const cardmeber2023=()=>import("@/views/question/cardmeber2023");
const card2023=()=>import("@/views/question/card2023");
const SFExpress=()=>import("@/views/SFExpress");
const SFExpressDetail=()=>import("@/views/SFExpress/detail");

const Redirect=()=>import("@/views/redirect");


Vue.use(VueRouter);

const routes = [
  


  {


    path: "/",
    component: BallTool,
    children: [

      {
        path: "/",
        component: Layout,
        children: [
          { path: "/", name: "home", component: Home, meta: { isAlive: true } },
          { path: "/starplan", name: "starplan", component: Starplan, meta: { isAlive: true } },
          { path: "/stardetail", name: "stardetail", component: StarDetail, },

          { path: "/question", name: "question", component: Question, meta: { isAlive: true } },
          {
            path: "/question/detail",
            name: "questiondetail",
            component: QuestionDetail,
          },
          {
            path: "/question/minidetail",
            name: "questionminidetail",
            component: QuestionMiniDetail,
          },
          { path: "/questiontype", name: "questiontype", component: QuestionType },
          { path: "/question/paradetail", name: "question_paradetail", component: paradetail },
          { path: "/question/cardmeber", name: "question_cardmeber", component: cardmeber },
          { path: "/question/longcard", name: "question_longcard", component: longcard },
          { path: "/question/cardmeber2023", name: "question_cardmeber2023", component: cardmeber2023 },
          { path: "/question/card2023", name: "question_card2023", component: card2023 },
          { path: "/sfexpress", name: "SFExpress", component: SFExpress },
          { path: "/sfexpress/detail", name: "SFExpressDetail", component: SFExpressDetail },

          {
            path: "/questiontype/detail",
            name: "questiontypedetail",
            component: QuestionDetailType,
          },
          {
            path: "/question/answerdetail",
            name: "answerdetail",
            component: AnserDetail,
          },
          {
            path: "/workSignin/mySignin",
            name: "mySignin",
            component: mySignin,
            meta: { title: "我的KM打卡" }
          },
          {
            path: "/luckdraw",
            name: "luckdraw",
            component: luckDraw,
            meta: { title: "抽奖" }
          },
          {
            path: "/violation",
            name: "violation",
            component: Violation,
            meta: { title: "违规通报" }
          },
          {
            path: "/luckdraw/record",
            name: "luckdrawrecord",
            component: luckDrawRecord,
            meta: { title: "抽奖" }
          },

          { path: "/video", name: "video", component: Video },
          { path: "/user", name: "user", component: User },
          { path: "/article", name: "article", component: Article },
          {
            path: "/article/detail",
            name: "article-detail",
            component: ArticleDetail,
          },
          { path: "/hd", name: "hdarticle", component: HD, meta: { title: "百年糊涂研发创意库" } },
          { path: "/hd/detail", name: "hdarticle-detail", component: HDDetail },

          { path: "/product/list", name: "product-list", component: ProductList },
          {
            path: "/product",
            name: "product",
            component: Product,
            meta: { isAlive: true },
          },
          {
            path: "/product/detail",
            name: "product-detail",
            component: ProductDetail,
          },
          { path: "/productprice", name: "productprice", component: ProductPrice, meta: { title: "产品价格" }, },
          { path: "/school", name: "school", component: School },
          {
            path: "/course",
            name: "course",
            component: Course,
            meta: { isAlive: true, title: "中酿学院" },
          },
          // 123
          // 中酿学院/课程中心
          {
            path: "/course/list",
            name: "course-list",
            component: CourseList,
            meta: { isAlive: true, title: "课程中心" },
          },
          // 中酿学院/讲师资源
          {
            path: "/course/teachers",
            name: "course-teachers",
            component: () => import("../views/course/courseTeachers.vue"),
            meta: { isAlive: true, title: "讲师资源" },
          },
          {
            path: "/course/class",
            name: "course-Class",
            component: CourseClass,
            meta: { isAlive: true, title: "课程分类" },
          },
          // 中酿学院/课程包
          {
            path: "/course/coursePackets",
            name: "course-packets",
            component: () => import("../views/course/coursePackets.vue"),
            // meta: { isAlive: true },
            meta: { title: "课程包" },
          },
          // 中酿学院课程包详情
          {
            path: "/course/coursePacketsItems",
            name: "course-packets-items",
            component: () => import("../views/course/coursePacketsItems.vue"),
            meta: { isAlive: true },

          },
          // 中酿学院/精品专区
          {
            path: "/course/CourseDesignGoods",
            name: "course-designgoods",
            component: () => import("../views/course/CourseDesignGoods"),
          },
          // 中酿学院/学习排行
          {
            path: "/course/LearnRankList",
            name: "course-learnRankList",
            component: () => import("../views/course/LearnRankList"),
            meta: { title: "学习排行榜" },
          },
          // 会议室预约
          {
            path: "/reservemeeting",
            name: "reservemeeting",
            component: () => import("../views/reserveMeeting"),
            meta: { isAlive: true, title: "中酿会议室预定" },
          },
          // 会议室预约详情
          {
            path: "/reservemeeting/meetingdetail",
            name: "reservemeeting-meetingdetail",
            component: () => import("../views/reserveMeeting/MeetingDetail"),
            // meta: { isAlive: true },
          },
          // 我的预约
          {
            path: "/reservemeeting/myappointment",
            name: "reservemeeting/myappointment",
            component: () => import("../views/reserveMeeting/MyAppointment"),
            // meta: { isAlive: true },
          },
          // 预约会议室讯息
          {
            path: "/reservemeeting/reservedMsg",
            name: "reservemeeting/reservedMsg",
            component: () => import("../views/reserveMeeting/reservedMsg"),
          },
          {
            path: "/course/detail",
            name: "course-detail",
            component: CourseDetail,
          },

          {
            path: "/course/courseofflinedetail",
            name: "course-offline-detail",
            component: CourseOfflineDetail,
          },

          {
            path: "/clouddisk",
            name: "clouddisk",
            component: CloudDisk,
            meta: { isAlive: true, title: "中酿资料库" },
          },
          {
            path: "/clouddisk/detail",
            name: "clouddisk-detail",
            component: CloudDiskDetail,
          },

          {
            path: "/bbs",
            name: "bbs-list",
            component: BBSList,
            meta: { isAlive: true, title: "中酿心声" },
          },
          { path: "/bbs/detail", name: "bbs-detail", component: BBSDetail },
          { path: "/search/list", name: "search-list", component: SearchList },
          {
            path: "s7strategy/list",
            name: "s7strategy-list",
            component: S7Strategy,
            meta: { title: "进阶S7攻略" }
          },
          {
            path: "score/rank",
            name: "score-rank",
            component: ScoreRank,
          },
          { path: "ad", name: "ad", component: Ad },
          { path: "ad/list", name: "ad-list", component: AdList },
          { path: "eshop", name: "eshop", component: Eshop, },
          { path: "eshop/detail", name: "eshop-detail", component: EshopDetail },
          { path: "project", name: "product-project", component: ProductProject, meta: { title: "项目周进度" } },
          { path: "/worklog", name: "user-worklog-list", component: worklogList, meta: { title: "工作日志" } },

          {
            path: "/worklog/detail",
            name: "user-worklog-detail",
            component: worklogDetail,
            meta: { title: "工作日志" }
          },

          { path: "/share", name: "share-add", component: Share },
          {
            path: "/workSignin/departmentData",
            name: "workSignin-departmentData",
            component: workSigninDepartmentData,
          },

          {
            path: "/customizedwineneed",
            name: "user-customizedwineneed-list",
            component: customizedWineNeedList,
          },
          {
            path: "/customizedwineneed/add",
            name: "user-customizedwineneed-add",
            component: customizedWineNeedAdd,
          },
        ],
      },
      { path: "/bbs/add", name: "bbs-add", component: AddBBS },
      { path: "/course/add", name: "course-add", component: AddCourse, meta: { title: "授课意向提报" } },
      { path: "/worklog/add", name: "user-worklog-add", component: worklogAdd, meta: { title: "工作日志" } },
      { path: "/test", name: "test", component: Test },
      {
        path: "/user/changePassWord",
        name: "user-changePassWord",
        component: ChangePassWord,
      },
      {
        path: "/user/changeuserinfo",
        name: "user-ChangeUserInfo",
        component: ChangeUserInfo,
      },

      { path: "/user/feedback", name: "user-feedback", component: FeedBack },
      {
        path: "/user/usercollect",
        name: "user-UserCollect",
        component: UserCollect,
      },

      { path: "/user/bindWX", name: "user-bindWX", component: BindWX },
      { path: "/user/bindWXPre", name: "user-bindWXPre", component: BindWXPre },
      { path: "/user/bindA3", name: "user-bindA3", component: BindA3 },
      { path: "/user/mycourse", name: "user-mycourse", component: MyCourse },
      { path: "/user/mybbs", name: "user-mybbs", component: MyBBS },
      { path: "/user/myscore", name: "user-myscore", component: MyScore },
      { path: "/user/profile", name: "user-profile", component: UserProfile },
      { path: "/user/settingList", name: "SettingList", component: SettingList },
      {
        path: "/user/systemmessage",
        name: "user-systemMessage",
        component: SystemMessage,
      },
      {
        path: "/user/systemmessagedetail",
        name: "user-systemMessageDetail",
        component: SystemMessageDetail,
      },
      {
        path: "/user/userBirthday",
        name: "user-userBirthday",
        component: SystemMessageBirthday,
      },
      {
        path: "/user/userJoinDate",
        name: "user-JoinDate",
        component: SystemMessageJoinDate,
      },
      {
        path: "/userHomepage",
        name: "userHomepage",
        component: UserHomepage,
        meta: { isAlive: true },
      },

      { path: "/user/mycomment", name: "user-mycomment", component: MyComment },
      { path: "/user/mycommentlike", name: "user-mycommentlike", component: MyCommentLike, meta: { isAlive: true } },

      { path: "/search", name: "search", component: Search },
      { path: "/search/result", name: "search-result", component: SearchResult },

      {
        path: "/productarrival",
        name: "productarrival",
        component: ProductArrival,
        meta: { title: "产品生产到货通报" }
      },
      {
        path: "/productarrival/arrivaldetail",
        name: "arrivald-etail",
        component: ArrivalDetail,
      },
      {
        path: "/productarrival/circulardetail",
        name: "circular-detail",
        component: CircularDetail,
      },



      { path: "/coursecomment", name: "coursecomment", component: CourseComment },
      { path: "/qa", name: "kmqa", component: KManser },
      {
        path: "/user/courseoffline",
        name: "user-mycourseoffline",
        component: MyCourseOffline,
        meta: { isAlive: true },
      },
      {
        path: "/user/usercoursetime",
        name: "user-coursetime",
        component: UserCourseTime,
      },

      {
        path: "/user/CheckSmsCode",
        name: "user-CheckSmsCode",
        component: CheckSmsCode,
      },
      {
        path: "/user/PreChangePassWord",
        name: "user-PreChangePassWord",
        component: PreChangePassWord,
      },

      {
        path: "/user/findpassword",
        name: "user-FindPassWord",
        component: FindPassWord,
      },
      { path: "/mockuser", name: "mockUser", component: mockUser },
      { path: "/workSignin", name: "workSignin", component: workSignin },

      {
        path: "/user/mycourseofflinedetail",
        name: "user-mycourseoffline-detail",
        component: MyCourseOfflineDetail,
      },

      {
        path: "/user/mycertificate",
        name: "user-myCertificate",
        component: myCertificate,
      },

      {
        path: "/user/usercertificate",
        name: "user-userCertificate",
        component: userCertificate,
      },
      {
        path: "/user/mycertificatedetail",
        name: "user-myCertificate-detail",
        component: myCertificateDetail,
      },
      {
        path: "/u/sign",
        name: "user-mycourseoffline-Signin",
        component: MyCourseofflineSignin,
      },
      {
        path: "/annualmeeting/index",
        name: "annualmeeting",
        component: Annualmeeting,
      },
      {
        path: "/frame",
        name: "frame",
        component: frame,
      },
      {
        path: "/exam",
        name: "exam",
        component: exam,
      },
      {
        path: "/exam/answerList",
        name: "answerList",
        component: answerList,
      },
      {
        path: "/exam/answerDetail",
        name: "answerDetail",
        component: answerDetail,
      },
      {
        path: "/openmin/km",
        name: "openminKM",
        component: Openminkm,
      },

      {
        path: "/user/mysubscribe",
        name: "usermysubscribe",
        component: MySubscribe,
      },
      {
        path: "/dealer",
        name: "dealer",
        component: Dealer,
      },
      {
        path: "/dealer/statindex",
        name: "statindex",
        component: StatIndex,
      },
      {
        path: "/dealerstat",
        name: "dealerstat",
        component: DealerStat,
      },
      {
        path: "/dealer/edite",
        name: "EditeDealer",
        component: EditeDealer,
      },
      {
        path: "/dealer/dealercampaign",
        name: "dealercampaign",
        component: DealerCampaign,
      },
      {
        path: "/dealer/campaigndetail",
        name: "campaigndetail",
        component: CampaignDetail,
      },
      {
        path: "/dealer/autotest",
        name: "autotest",
        component: autotest,
      },
      {path:"/redirect",name: "redirect", component:Redirect }

    ]
  },
  { path: "/login", name: "login", component: Login },
  { path: "/z/list", name: "article-plus", component: ArticlePlus },
  { path: "/z", name: "articledetail-plus", component: ArticleDetailPlus,meta: { title: "  " } },
  {path:"/vip",name: "vip", component: vip },

  


  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];
/** 解决跳转重复路由报错问题 */

const router = new VueRouter({
  routes,
});

router.beforeEach((to, _, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  else {
    document.title = "中酿KM移动端";
  }

  next();
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
