<template>
  <div class="container">
    <div class="user-profile" :style="{
      height: learnInfo.hasLearn || learnInfo.hasTeach ? '220px' : '200px',
    }">
      <div class="info">
        <van-image round :src="userInfo.headImgurl" @click="toUserInfo" />
        <h3 class="name">
          {{ userInfo.trueName }} | {{ userInfo.code }}| {{ userInfo.grade }}
          <br />
          积分：{{ myScore }}
          学习累计积分：{{ courseScore }}
          <!-- <van-tag size="mini">申请认证</van-tag> -->
          <br />
          <userIcon :iconstring="userInfo.icon" iconSize="L"></userIcon>
        </h3>
      </div>

      <div style="margin-left: 45px">
        <van-tag round size="large" type="primary" @click="toHomepage">我的个人主页</van-tag>
      </div>

      <div style="paiding-left: 20px"></div>
      <van-divider dashed :style="{ margin: '0px' }" v-if="learnInfo.hasLearn || learnInfo.hasTeach">课程时长</van-divider>
      <van-row>
        <van-col span="6" v-if="learnInfo.hasLearn">
          <p>{{ learnInfo.totalLearnedLen }} h</p>
          <p>已学</p>
        </van-col>
        <van-col span="6" v-if="learnInfo.hasTeach">
          <p>{{ learnInfo.offlineTeachedLen }} h</p>
          <p>已授</p>
        </van-col>
        <van-col span="6" v-if="
          learnInfo.hasLearn &&
          learnInfo.learnLen > learnInfo.totalLearnedLen
        ">
          <p>
            {{ (learnInfo.learnLen - learnInfo.totalLearnedLen).toFixed(1) }}
            h
          </p>
          <p>未学</p>
        </van-col>
        <van-col span="6" v-if="
          learnInfo.hasLearn &&
          learnInfo.learnLen <= learnInfo.totalLearnedLen
        ">
          <p>0 h</p>
          <p>未学</p>
        </van-col>
        <van-col span="6" v-if="
          learnInfo.hasTeach &&
          learnInfo.teachLen >= learnInfo.offlineTeachedLen
        ">
          <p>
            {{ (learnInfo.teachLen - learnInfo.offlineTeachedLen).toFixed(1) }}
            h
          </p>
          <p>未授</p>
        </van-col>
      </van-row>
      <!-- <van-row>
      <van-col span="6">
        <p>{{userInfo.art_count}}</p>
        <p>动态</p>
      </van-col>
      <van-col span="6">
        <p>{{userInfo.follow_count}}</p>
        <p>关注</p>
      </van-col>
      <van-col span="6">
        <p>{{userInfo.fans_count}}</p>
        <p>粉丝</p>
      </van-col>
      <van-col span="6">
        <p>{{userInfo.fans_count}}</p>
        <p>被赞</p>
      </van-col>
    </van-row> -->
    </div>
    <!-- <van-row class="user-links">
          <van-col span="8">
            <van-icon name="newspaper-o" color="#7af" />我的作品
          </van-col>
          <van-col span="8">
            <van-icon name="star-o" color="#f00" />我的收藏
          </van-col>
          <van-col span="8">
            <van-icon name="tosend" color="#fa0" />阅读历史
          </van-col>
        </van-row> -->

    <van-grid :column-num="4">
      <van-grid-item icon="tv-o" text="线下课程" to="/user/courseoffline" />
      <van-grid-item icon="video-o" text="线上课程" to="/user/mycourse" />
      <van-grid-item icon="bar-chart-o" text="我的学习时长" to="/user/usercoursetime" />
      <van-grid-item icon="points" text="我的积分" to="/user/myscore?blockId=0" />
    </van-grid>
    <van-grid :column-num="4">
      <van-grid-item icon="apps-o" text="我的工作台" to="/user/binda3" />
      <van-grid-item icon="star-o" text="我的收藏" to="/user/usercollect" />

      <van-grid-item icon="smile-comment-o" text="我的心声" to="/user/mybbs" />
      <van-grid-item icon="records" text="评论点赞" to="/user/mycommentlike" :badge="getCommentLikeCount()" />

      <van-grid-item v-if="
        this.$store.state.user.mangedHRDepartmentIds &&
        this.$store.state.user.mangedHRDepartmentIds.length > 0
      " icon="/image/userbirthday.png" text="员工生日" to="/user/userBirthday" />
      <van-grid-item v-if="
        this.$store.state.user.mangedHRDepartmentIds &&
        this.$store.state.user.mangedHRDepartmentIds.length > 0
      " icon="/image/userjoindate.png" text="员工司龄" to="/user/userJoinDate" />

      <van-grid-item icon="/image/certificate.png" text="我的证书" to="/user/mycertificate" />
      <van-grid-item icon="/image/exam.png" text="我的考试" to="/exam/answerList" />
      <van-grid-item icon="/image/scoreblcok1.png" text="我的能量值" to="/user/myscore?blockId=1" />
      <van-grid-item icon="/image/star.png" text="我的星光" to="/starplan" />
      <van-grid-item icon="/image/mini.png" text="绑定中酿优享" @click="toYouxiangMini" />

    </van-grid>
    <van-grid :column-num="4">
    </van-grid>

    <van-cell-group class="user-group">
      <!-- <van-cell icon="records" title="收到的评论" to="/user/mycomment" is-link>
        <template #title>
          <span class="custom-title">收到的评论</span>
          <van-badge
            v-if="getMyComment().messageCount != undefined"
            :content="getMyComment().messageCount"
            max="99"
          />
        </template>
</van-cell> -->
      <!-- <van-cell icon="edit" title="编辑资料" to="/user/profile" is-link />
            <van-cell icon="chat-o" title="小智同学" to="/user/chat" is-link />
            <van-cell icon="setting-o" title="系统设置" is-link /> -->
      <!-- <van-cell icon="points" title="我的积分" to="/user/myscore" is-link /> -->
      <!-- <van-cell icon="records" title="我的工作台" to="/user/binda3" is-link /> -->



      <van-cell icon="bullhorn-o" title="系统通知" to="/user/systemmessage" is-link>
        <template #title>
          <span class="custom-title">系统通知</span>
          <van-badge v-if="getMessage().messageCount != undefined" :content="getMessage().messageCount" max="99" />
        </template>
      </van-cell>


      <van-cell icon="chat-o" title="绑定微信" @click="bindwx" is-link v-if="appid != ''" />
      <van-cell icon="edit" title="修改密码" to="/user/changePassWord" is-link />
      <van-cell icon="setting-o" title="设置" to="/user/settinglist" is-link>
        <template #title>
          <span class="custom-title">设置</span>
          <i :class="gethasSystmeUpdate() ? 'icon_num_red' : ''"></i>
        </template>
      </van-cell>
    
    </van-cell-group>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/user";
import { GetAppId } from "@/api/wxuser";
import { GetMyScore } from "@/api/score";
import { getUserLearn } from "@/api/user";

import { mapMutations, mapGetters } from "vuex";
//import store from '@/store';
import { Toast } from "vant";

import UserIcon from "../components/userIcon";

export default {
  name: "user",
  components: {
    UserIcon,
  },
  data() {
    return {
      comment: {},
      systemmessage: {},
      like: {},

      myScore: 0,
      courseScore: 0,//学习积分
      appid: "",
      userInfo: {},
      learnInfo: {
        hasLearn: false,
        hasTeach: false,
        learnLen: 0,
        totalLearnedLen: 0,
        offlineTeachedLen: 0,
        teachLen: 0,
      },
      isWeixin:
        navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1,
      isMiniprogram: false,
    };
  },
  created() {
    this.initData();
    this.getUser();
    this.GetAppId();
    this.getLearn();
  },

  methods: {
    ...mapMutations(["clearUser", "updatePhoto", "updateHasSystemUpdate"]),
    ...mapGetters([
      "gethasSystmeUpdate",
      "getsystemMessage",
      "getMessage",
      "getMyComment",
      "getMyLike",
      "getCommentLikeCount"
      ,
    ]),

    async initData() {
      if (this.isWeixin) {
        try {
          //var jsConfig=await GetAppJsConfig(encodeURIComponent(location.href.split("#")[0]));

          let win = window;
          if (win.wx) {
            // win.wx.config({
            //   debug: false,
            //   appId: jsConfig.appId,
            //   timestamp: jsConfig.timestamp,
            //   nonceStr: jsConfig.nonceStr,
            //   signature: jsConfig.signature,
            //   jsApiList: [
            //     "checkJsApi",

            //   ],
            // });
            win.wx.ready(() => {
              console.log("微信jssdk加载成功!!!!");
            });
            win.wx.error((res) => {
              console.log("微信jssdk加载失败!!!" + res.errMsg);
            });

            win.wx.miniProgram.getEnv((res) => {
              console.log(res);
              if (res.miniprogram) {
                console.log("在小程序里！！！");
              } else {
                console.log("不在小程序！！！");
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("不在微信中");
      }

      let aresult = await GetMyScore(0);
      this.myScore = aresult.data.data.canUseScore;
      this.courseScore = aresult.data.data.courseScore;
    },


    /*
    检查是否是在微信小程序中
     */

    async checkIsMiniProgram() {
      try {
        if (this.isWeixin) {
          let win = window;
         
          if (!win.wx || !win.wx.miniProgram) {
            Toast("请在微信小程序中打开");
            return false;
          }
          
          const res = await new Promise((resolve, reject) => {
            let timeout = setTimeout(() => reject(new Error("getEnv timeout")), 2000);
            win.wx.miniProgram.getEnv((env) => {
              clearTimeout(timeout);
              console.log("getEnv callback triggered:", env);
              resolve(env);
            });
          });
        
          if (res.miniprogram) {
            return true;
          }
        }
      } catch (error) {
        console.error("Error in checkIsMiniProgram:", error);
      }
      Toast("请在微信小程序中打开");
      return false;

    },


    async toYouxiangMini() {
      console.log("toYouxiangMini");
      const isMiniprogram = await this.checkIsMiniProgram();
      if (!isMiniprogram) {
        return;
      }

      //本页面地址，选完地址会跳转过来， 如果
      let thispageurl = encodeURIComponent("https://am.bjznpz.cn/vip/#/bindznuser?znuserid=" + this.$store.state.user.id);
      let path =encodeURIComponent(`/pages/webview21/webview2?url=${thispageurl}`);
      //本页在小程序的路径
      let minipath = `/pages/bridge/bridge?typeid=5&appid=wx28d124a6a630f2e9&path=${path}&appname=${encodeURIComponent('打开中酿优享程序')}`;
      // 跳转到原生桥接
      window.wx.miniProgram.navigateTo({
        url: minipath
      })
    },


    GetMessage() {
      let data = this.getsystemMessage();
      if (data != undefined && data.length > 0) {
        data.forEach((element) => {
          if (element.messageType == 1) {
            this.comment = element;
          }
          if (element.messageType == 3) {
            this.systemmessage = element;
          }
          if (element.messageType == 2) {
            this.like = element;
          }

        });
      }
    },
    async GetAppId() {
      try {
        const data = await GetAppId();
        if (data.data.success) {
          this.appid = data.data.data;
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        console.log(error);
      }
    },
    toUserInfo() {
      this.$router.push({ path: "/user/changeuserinfo" });
    },
    toHomepage() {
      this.$router.push({
        path: "/userHomepage",
        query: {
          para: this.userInfo.id,
        },
      });
    },

    async getUser() {
      var aresult = await getUserInfo();
      this.userInfo = aresult.data.data;
    },
    async getLearn() {
      var result = await getUserLearn();
      this.learnInfo = result.data.data;
    },
    // 退出登录
    async lgout() {
      try {
        await this.$dialog.confirm({
          title: "提示",
          message: "确定要退出登录么",
        });
        this.clearUser();
        //this.$router.push('/login')
        window.location.replace(window.location.origin + "/#/login");
        //window.location.reload(true);
        // window.location.href=window.location.origin+"/#/login";
        // window.location.reload(true);
      } catch (error) {
        console.log(error);
      }
    },
    bindwx() {
      //this.$router.push('/user/bindwx?code=123code123');
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.appid +
        "&redirect_uri=https%3a%2f%2fkm.bjznpz.com%2f%23%2fuser%2fbindwx&response_type=code&scope=snsapi_userinfo#wechat_redirect";
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.icon_num_red {
  margin-left: 8px;
  margin-right: 5px;
  font-weight: normal;
  background: #df3e3e;
  display: inline-block;
  width: 10px;
  height: 10px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 16px;
  *line-height: 15px;
  border-radius: 50%;
  font-family: Arial;
}

.user {
  &-profile {
    width: 100%;
    height: 150px;
    display: block;
    background: rgb(157, 29, 34);
    color: #fff;

    .info {
      display: flex;
      padding: 20px;
      align-items: center;

      .van-image {
        width: 64px;
        height: 64px;
      }

      .name {
        font-size: 16px;
        font-weight: normal;
        margin-left: 10px;
      }

      .van-tag {
        background: #fff;
        color: #3296fa;
      }
    }

    p {
      margin: 0;
      text-align: center;
    }
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
      padding-bottom: 5px;
    }
  }
}
</style>
