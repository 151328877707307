var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"user-profile",style:({
      height: _vm.learnInfo.hasLearn || _vm.learnInfo.hasTeach ? '220px' : '200px',
    })},[_c('div',{staticClass:"info"},[_c('van-image',{attrs:{"round":"","src":_vm.userInfo.headImgurl},on:{"click":_vm.toUserInfo}}),_c('h3',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.userInfo.trueName)+" | "+_vm._s(_vm.userInfo.code)+"| "+_vm._s(_vm.userInfo.grade)+" "),_c('br'),_vm._v(" 积分："+_vm._s(_vm.myScore)+" 学习累计积分："+_vm._s(_vm.courseScore)+" "),_c('br'),_c('userIcon',{attrs:{"iconstring":_vm.userInfo.icon,"iconSize":"L"}})],1)],1),_c('div',{staticStyle:{"margin-left":"45px"}},[_c('van-tag',{attrs:{"round":"","size":"large","type":"primary"},on:{"click":_vm.toHomepage}},[_vm._v("我的个人主页")])],1),_c('div',{staticStyle:{"paiding-left":"20px"}}),(_vm.learnInfo.hasLearn || _vm.learnInfo.hasTeach)?_c('van-divider',{style:({ margin: '0px' }),attrs:{"dashed":""}},[_vm._v("课程时长")]):_vm._e(),_c('van-row',[(_vm.learnInfo.hasLearn)?_c('van-col',{attrs:{"span":"6"}},[_c('p',[_vm._v(_vm._s(_vm.learnInfo.totalLearnedLen)+" h")]),_c('p',[_vm._v("已学")])]):_vm._e(),(_vm.learnInfo.hasTeach)?_c('van-col',{attrs:{"span":"6"}},[_c('p',[_vm._v(_vm._s(_vm.learnInfo.offlineTeachedLen)+" h")]),_c('p',[_vm._v("已授")])]):_vm._e(),(
          _vm.learnInfo.hasLearn &&
          _vm.learnInfo.learnLen > _vm.learnInfo.totalLearnedLen
        )?_c('van-col',{attrs:{"span":"6"}},[_c('p',[_vm._v(" "+_vm._s((_vm.learnInfo.learnLen - _vm.learnInfo.totalLearnedLen).toFixed(1))+" h ")]),_c('p',[_vm._v("未学")])]):_vm._e(),(
          _vm.learnInfo.hasLearn &&
          _vm.learnInfo.learnLen <= _vm.learnInfo.totalLearnedLen
        )?_c('van-col',{attrs:{"span":"6"}},[_c('p',[_vm._v("0 h")]),_c('p',[_vm._v("未学")])]):_vm._e(),(
          _vm.learnInfo.hasTeach &&
          _vm.learnInfo.teachLen >= _vm.learnInfo.offlineTeachedLen
        )?_c('van-col',{attrs:{"span":"6"}},[_c('p',[_vm._v(" "+_vm._s((_vm.learnInfo.teachLen - _vm.learnInfo.offlineTeachedLen).toFixed(1))+" h ")]),_c('p',[_vm._v("未授")])]):_vm._e()],1)],1),_c('van-grid',{attrs:{"column-num":4}},[_c('van-grid-item',{attrs:{"icon":"tv-o","text":"线下课程","to":"/user/courseoffline"}}),_c('van-grid-item',{attrs:{"icon":"video-o","text":"线上课程","to":"/user/mycourse"}}),_c('van-grid-item',{attrs:{"icon":"bar-chart-o","text":"我的学习时长","to":"/user/usercoursetime"}}),_c('van-grid-item',{attrs:{"icon":"points","text":"我的积分","to":"/user/myscore?blockId=0"}})],1),_c('van-grid',{attrs:{"column-num":4}},[_c('van-grid-item',{attrs:{"icon":"apps-o","text":"我的工作台","to":"/user/binda3"}}),_c('van-grid-item',{attrs:{"icon":"star-o","text":"我的收藏","to":"/user/usercollect"}}),_c('van-grid-item',{attrs:{"icon":"smile-comment-o","text":"我的心声","to":"/user/mybbs"}}),_c('van-grid-item',{attrs:{"icon":"records","text":"评论点赞","to":"/user/mycommentlike","badge":_vm.getCommentLikeCount()}}),(
        this.$store.state.user.mangedHRDepartmentIds &&
        this.$store.state.user.mangedHRDepartmentIds.length > 0
      )?_c('van-grid-item',{attrs:{"icon":"/image/userbirthday.png","text":"员工生日","to":"/user/userBirthday"}}):_vm._e(),(
        this.$store.state.user.mangedHRDepartmentIds &&
        this.$store.state.user.mangedHRDepartmentIds.length > 0
      )?_c('van-grid-item',{attrs:{"icon":"/image/userjoindate.png","text":"员工司龄","to":"/user/userJoinDate"}}):_vm._e(),_c('van-grid-item',{attrs:{"icon":"/image/certificate.png","text":"我的证书","to":"/user/mycertificate"}}),_c('van-grid-item',{attrs:{"icon":"/image/exam.png","text":"我的考试","to":"/exam/answerList"}}),_c('van-grid-item',{attrs:{"icon":"/image/scoreblcok1.png","text":"我的能量值","to":"/user/myscore?blockId=1"}}),_c('van-grid-item',{attrs:{"icon":"/image/star.png","text":"我的星光","to":"/starplan"}}),_c('van-grid-item',{attrs:{"icon":"/image/mini.png","text":"绑定中酿优享"},on:{"click":_vm.toYouxiangMini}})],1),_c('van-grid',{attrs:{"column-num":4}}),_c('van-cell-group',{staticClass:"user-group"},[_c('van-cell',{attrs:{"icon":"bullhorn-o","title":"系统通知","to":"/user/systemmessage","is-link":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"custom-title"},[_vm._v("系统通知")]),(_vm.getMessage().messageCount != undefined)?_c('van-badge',{attrs:{"content":_vm.getMessage().messageCount,"max":"99"}}):_vm._e()]},proxy:true}])}),(_vm.appid != '')?_c('van-cell',{attrs:{"icon":"chat-o","title":"绑定微信","is-link":""},on:{"click":_vm.bindwx}}):_vm._e(),_c('van-cell',{attrs:{"icon":"edit","title":"修改密码","to":"/user/changePassWord","is-link":""}}),_c('van-cell',{attrs:{"icon":"setting-o","title":"设置","to":"/user/settinglist","is-link":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"custom-title"},[_vm._v("设置")]),_c('i',{class:_vm.gethasSystmeUpdate() ? 'icon_num_red' : ''})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }